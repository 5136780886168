var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { attrs: { "no-body": "" } },
    [
      _c("b-card-header", [
        _c(
          "div",
          [
            _c("b-card-title", { staticClass: "mb-1" }, [
              _vm._v(" Statistics "),
            ]),
            _c("b-card-sub-title", [
              _vm._v("Commercial networks and enterprises"),
            ]),
          ],
          1
        ),
      ]),
      _c(
        "b-card-body",
        [
          _c("chartjs-component-line-chart", {
            attrs: {
              height: 400,
              data: _vm.chartjsData.lineChart.data,
              options: _vm.chartjsData.lineChart.options,
              plugins: _vm.plugins,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }