var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { attrs: { "no-body": "" } },
    [
      _c(
        "b-card-header",
        [
          _c("b-card-title", [_vm._v("Latest Statistics")]),
          _c(
            "div",
            { staticClass: "d-flex align-items-center" },
            [
              _c("feather-icon", {
                attrs: { icon: "CalendarIcon", size: "16" },
              }),
              _c("flat-pickr", {
                staticClass:
                  "form-control flat-picker bg-transparent border-0 shadow-none",
                attrs: { config: { mode: "range" }, placeholder: "YYYY-MM-DD" },
                model: {
                  value: _vm.rangePicker,
                  callback: function ($$v) {
                    _vm.rangePicker = $$v
                  },
                  expression: "rangePicker",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-card-body",
        [
          _c("chartjs-component-bar-chart", {
            attrs: {
              height: 400,
              data: _vm.chartjsData.latestBarChart.data,
              options: _vm.chartjsData.latestBarChart.options,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }