var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { attrs: { "no-body": "" } },
    [
      _c(
        "b-card-header",
        [
          _c("b-card-title", [_vm._v("Bubble Chart")]),
          _c(
            "div",
            { staticClass: "d-flex align-items-center flex-wrap" },
            [
              _c("h5", { staticClass: "font-weight-bolder mb-0 mr-1" }, [
                _vm._v(" $ 100,000 "),
              ]),
              _c(
                "b-badge",
                { attrs: { variant: "light-secondary" } },
                [
                  _c("feather-icon", {
                    staticClass: "text-danger",
                    attrs: { icon: "ArrowDownIcon", size: "18" },
                  }),
                  _c("span", { staticClass: "ml-25" }, [_vm._v("20%")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-card-body",
        [
          _c("chartjs-component-bubble-chart", {
            attrs: {
              height: 500,
              data: _vm.chartjsData.bubbleChart.data,
              options: _vm.chartjsData.bubbleChart.options,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }